import { GroupModel } from "../models/ServerModels";
import { getSlice } from "./BaseSlice";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

export const groupsSlice = getSlice<GroupModel>("groups");
const { setData, setLoading, modifyItem, removeItem, addItem } =
  groupsSlice.actions;

export const modifyGroup = modifyItem;

const baseApiUrl = "/api/groups/";

export const fetchGroups =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await fetch(baseApiUrl, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(setData(await response.json()));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getOneGroup =
  (id: string, token: string): AppThunk =>
  async (dispatch) => {
    try {
      const newItemVM = await fetch(baseApiUrl + id, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(modifyItem(await newItemVM.json()));
    } catch (error) {
      console.log(error);
    }
  };

export const createGroup =
  (item: GroupModel, token: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await fetch(baseApiUrl, {
        method: "POST",
        body: JSON.stringify(item),
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      const newItemVM = await fetch(baseApiUrl + json.id, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(addItem(await newItemVM.json()));
    } catch (error) {
      console.log(error);
    }
  };

export const deleteGroup =
  (id: string, token: string): AppThunk =>
  async (dispatch) => {
    try {
      await fetch(baseApiUrl + id, {
        method: "DELETE",
        headers: { authorization: "Bearer " + token },
      });
      dispatch(removeItem(id));
    } catch (err) {
      console.log(err);
    }
  };

export const editGroup =
  (id: string, item: GroupModel, token: string): AppThunk =>
  async (dispatch) => {
    try {
      await fetch(baseApiUrl + id, {
        method: "PUT",
        body: JSON.stringify({
          Id: id,
          ...item,
        }),
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      const newItemVM = await fetch(baseApiUrl + id, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(modifyItem(await newItemVM.json()));
    } catch (err) {
      console.log(err);
    }
  };

export const groupsSelector = (state: RootState) => state.groups;
export default groupsSlice.reducer;
