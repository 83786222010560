import { TildaLeadModel } from "../models/ServerModels";
import { getSlice } from "./BaseSlice";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

export const tildaLeadsSlice = getSlice<TildaLeadModel>("tildaleads");
const { setLoading, setData, modifyItem, removeItem } = tildaLeadsSlice.actions;
const baseApiUrl = "/api/tildaleads/";

export const fetchTildaLeads =
  (token: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const response = await fetch(baseApiUrl, {
          headers: { authorization: "Bearer " + token },
        });
        dispatch(setData(await response.json()));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    };

export const editTildaLead =
  (id: string, item: TildaLeadModel, token: string): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl + id, {
          method: "PUT",
          body: JSON.stringify({
            Id: id,
            ...item,
          }),
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        const newItemVM = await fetch(baseApiUrl + id, {
          headers: { authorization: "Bearer " + token },
        });
        dispatch(modifyItem(await newItemVM.json()));
      } catch (err) {
        console.log(err);
      }
    };

export const deleteTildaLead =
  (id: string, token: string): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl + id, {
          method: "DELETE",
          headers: { authorization: "Bearer " + token },
        });
        dispatch(removeItem(id));
      } catch (err) {
        console.log(err);
      }
    };

export const tildaLeadsSelector = (state: RootState) => state.tildaLeads;
export default tildaLeadsSlice.reducer;
