import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GroupModel } from "../../models/ServerModels";
import { SortFunc } from "../../models/Sorter";
import { appSelector } from "../../stores/AppSlice";
import { channelsSelector, fetchChannels } from "../../stores/ChannelsSlice";
import {
  createGroup,
  deleteGroup,
  editGroup,
  fetchGroups,
  getOneGroup,
  groupsSelector,
} from "../../stores/GroupsSlice";
import { fetchPhones, phonesSelector } from "../../stores/PhonesSlice";
import { BaseCreateModal } from "../Base/BaseCreateModal";
import { EditableTable } from "../Base/EditableTable";
import { EmptyTable } from "../Base/EmptyTable";
import { PhonesSelector } from "./PhonesSelector";
import { SelectChannelItem } from "./SelectChannelItem";

export const Groups = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(appSelector);
  let { data, isLoading } = useSelector(groupsSelector);
  const { data: phones, isLoading: isPhonesLoading } =
    useSelector(phonesSelector);
  const chns = useSelector(channelsSelector).channels;
  const [channels, setChannels] = useState<{ id: string; name: string }[]>();
  const [filterGrp, setFilterGrp] = useState<string[]>();
  const [isModalShown, setIsModalShown] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");

  useEffect(() => {
    setChannels(
      chns
        .map((x) => {
          return { id: x.id, name: x.name };
        })
        .filter((val, idx, self) => self.indexOf(val) === idx && val)
    );
  }, [chns]);

  useEffect(() => {
    dispatch(fetchGroups(token));
    dispatch(fetchChannels(token));
    dispatch(fetchPhones(token));
  }, []);

  if (filterGrp && filterGrp.length) {
    data = data.filter((x) => filterGrp?.indexOf(x.channelId) !== -1);
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      editable: true,
      width: "22%",
      sorter: (x: GroupModel, y: GroupModel) => SortFunc("name", x, y),
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      editable: true,
      width: "22%",
      sorter: (x: GroupModel, y: GroupModel) => SortFunc("comment", x, y),
    },
    {
      title: "Телефоны",
      dataIndex: "phoneCount",
      editable: true,
      width: "22%",
      render: (text: any, row: GroupModel) => {
        if (!isPhonesLoading) {
          const numbersWord =
            text === 1
              ? "номер"
              : text === 2 || text === 3 || text === 4
              ? "номера"
              : "номеров";

          return (
            <Button
              type="link"
              onClick={() => {
                setIsModalShown(true);
                setSelectedGroupId(row.id);
              }}
            >
              {text} {numbersWord}
            </Button>
          );
        } else {
          <Spin />;
        }
      },
    },
    {
      title: "Канал",
      dataIndex: "channelName",
      editableDataIndex: "channelId",
      editable: true,
      width: "22%",
      sorter: (x: GroupModel, y: GroupModel) => SortFunc("channelName", x, y),
    },
  ];
  const formItems = [
    <Form.Item
      key="name"
      label="Название"
      name="name"
      rules={[{ required: true }]}
    >
      <Input maxLength={100} />
    </Form.Item>,
    <Form.Item key="comment" label="Комментарий" name="comment">
      <Input maxLength={500} type="text" />
    </Form.Item>,
    <Form.Item
      key="channelId"
      label="Канал"
      name="channelId"
      rules={[{ required: true }]}
    >
      <SelectChannelItem channels={channels} />
    </Form.Item>,
  ];

  const onSave = (id: string, item: GroupModel) => {
    dispatch(editGroup(id, item, token));
  };

  const extraButtons = (
    <>
      <BaseCreateModal<GroupModel>
        title="Новый аккаунт"
        formItems={formItems}
        createAction={createGroup}
      >
        {formItems.map((f) => f)}
      </BaseCreateModal>
    </>
  );

  const resultNode =
    isLoading === false ? (
      <>
        <EditableTable<GroupModel>
          loading={isLoading}
          dataSource={data}
          columns={columns}
          onSave={(id: string, item: GroupModel) => onSave(id, item)}
          onDelete={(id: string) => {
            dispatch(deleteGroup(id, token));
          }}
          extraTopButtons={extraButtons}
          title="Группы номеров"
          formItems={formItems}
        />
        <PhonesSelector
          shown={isModalShown}
          phones={phones}
          groupId={selectedGroupId}
          onModalClose={(p = false) => {
            if (p) {
              dispatch(getOneGroup(selectedGroupId, token));
            }
            setIsModalShown(false);
          }}
        />
      </>
    ) : (
      <EmptyTable columns={columns} />
    );

  return resultNode;
};
