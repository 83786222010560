import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appSelector } from "../../stores/AppSlice";

export const BaseCreateModal = <T extends unknown>({
  title,
  createAction,
  children,
}: any) => {
  const dispatch = useDispatch();
  const { token } = useSelector(appSelector);
  const [isModalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm<T>();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const okModal = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(() => {
        const fields = form.getFieldsValue();
        dispatch(createAction(fields, token));
        cancelModal();
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  };

  const cancelModal = () => {
    clearForm();
    setConfirmLoading(false);
    setModalVisible(false);
  };

  const clearForm = () => {
    form.resetFields();
    setConfirmLoading(false);
  };

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => setModalVisible(true)}
        style={{ marginBottom: 16 }}
      >
        Добавить
      </Button>
      <Modal
        confirmLoading={confirmLoading}
        title={title}
        visible={isModalVisible}
        onOk={okModal}
        onCancel={cancelModal}
        okText="Добавить"
        destroyOnClose
        okButtonProps={{ icon: <PlusOutlined />, htmlType: "submit" }}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          //size={"small"}
          validateMessages={{ required: "Это обязательное поле" }}
        >
          {children}
          <Form.Item key="clear" wrapperCol={{ offset: 17 }}>
            <Button type="link" onClick={clearForm}>
              Очистить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
