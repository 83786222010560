import {
  BugFilled,
  ContactsFilled,
  GlobalOutlined,
  HighlightOutlined,
  HomeFilled,
  NumberOutlined,
  PhoneFilled,
  PhoneOutlined,
  PieChartFilled,
  ProfileFilled,
  SettingOutlined,
  SlidersOutlined,
  StopOutlined,
  TeamOutlined,
  FundFilled,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { appSelector } from "../../stores/AppSlice";
import { TelegramIcon } from "../Base/Icons";

const { SubMenu } = Menu;

export const LeftMenu = () => {
  const [openKey, setOpenKey] = useState(["1"]);
  const [selectedKey, setSelectedKey] = useState("11");
  const location = useLocation();
  const { userProfile } = useSelector(appSelector);
  const up = userProfile as any;
  const history = useHistory();

  useEffect(() => {
    switch (location.pathname) {
      case "/channels":
        setSelectedKey("33");
        setOpenKey(["3", "33"]);
        break;
      case "/accounts":
        setSelectedKey("12");
        setOpenKey(["1", "12"]);
        break;
      case "/phones":
        setSelectedKey("13");
        setOpenKey(["1", "13"]);
        break;
      case "/groups":
        setSelectedKey("14");
        setOpenKey(["1", "14"]);
        break;
      case "/admin/users":
        setSelectedKey("21");
        setOpenKey(["2", "21"]);
        break;
      case "/admin/organizations":
        setSelectedKey("22");
        setOpenKey(["2", "22"]);
        break;
      case "/blacklist":
        setSelectedKey("15");
        setOpenKey(["1", "15"]);
        break;
      // case "/organization/users":
      //   setSelectedKey("31");
      //   setOpenKey(["3", "31"]);
      //   break;
      case "/organization/settings":
        setSelectedKey("32");
        setOpenKey(["3", "32"]);
        break;
      case "/tildaforms/leads":
        setSelectedKey("41");
        setOpenKey(["4", "41"]);
        break;
      case "/tildaforms/settings":
        setSelectedKey("42");
        setOpenKey(["4", "42"]);
        break;
      case "/tildaforms/sites":
        setSelectedKey("43");
        setOpenKey(["4", "43"]);
        break;
      case "/tildaforms/blacklist":
        setSelectedKey("44");
        setOpenKey(["4", "44"]);
        break;
      case "/statistic/phones":
        setSelectedKey("51");
        setOpenKey(["5", "51"]);
        break;
      default:
        setOpenKey(["1", "11"]);
        setSelectedKey("11");
        break;
    }
  }, [location.pathname]);

  return (
    <Menu
      theme="dark"
      collapsedWidth={75}
      selectedKeys={[selectedKey]}
      //defaultOpenKeys={openKey}
      openKeys={openKey}
      mode="inline"
    >
      <SubMenu
        key="1"
        icon={<PhoneFilled />}
        title="Телефония"
        onTitleClick={() => {
          setOpenKey(["1"]);
          history.push("/");
        }}
      >
        <Menu.Item key="11" icon={<PieChartFilled />}>
          <Link to="/">Монитор</Link>
        </Menu.Item>
        <Menu.Item key="12" icon={<ContactsFilled />}>
          <Link to="/accounts">Аккаунты</Link>
        </Menu.Item>
        <Menu.Item key="13" icon={<NumberOutlined />}>
          <Link to="/phones">Номера</Link>
        </Menu.Item>
        <Menu.Item key="15" icon={<StopOutlined />}>
          <Link to="/blacklist">Черный список</Link>
        </Menu.Item>
        <Menu.Item key="14" icon={<SettingOutlined />}>
          <Link to="/groups">Настройка</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="4"
        icon={<ProfileFilled />}
        title="Tildaforms"
        onTitleClick={() => {
          setOpenKey(["4"]);
          history.push("/tildaforms/leads");
        }}
      >
        <Menu.Item key="41" icon={<HighlightOutlined />}>
          <Link to="/tildaforms/leads">Заявки</Link>
        </Menu.Item>
        <Menu.Item key="43" icon={<GlobalOutlined />}>
          <Link to="/tildaforms/sites">Сайты</Link>
        </Menu.Item>
        <Menu.Item key="42" icon={<SlidersOutlined />}>
          <Link to="/tildaforms/settings">Настройки</Link>
        </Menu.Item>
        <Menu.Item key="44" icon={<StopOutlined />}>
          <Link to="/tildaforms/blacklist">Черный список</Link>
        </Menu.Item>
      </SubMenu>

      {up.role <= 2 && (
        <>
          <SubMenu
            key="5"
            icon={<FundFilled />}
            title="Статистика"
            onTitleClick={() => {
              setOpenKey(["5"]);
              history.push("/statistic/phones");
            }}
          >
            <Menu.Item key="51" icon={<PhoneOutlined />}>
              <Link to="/statistic/phones">По номерам</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="3"
            icon={<HomeFilled />}
            title="Организация"
            onTitleClick={() => {
              setOpenKey(["3"]);
              history.push("/organization/settings");
            }}
          >
            {/* <Menu.Item key="31" icon={<TeamOutlined />}>
                <Link to="/organization/users">Пользователи</Link>
              </Menu.Item> */}
            <Menu.Item key="32" icon={<SlidersOutlined />}>
              <Link to="/organization/settings">Настройки</Link>
            </Menu.Item>
            <Menu.Item key="33" icon={<TelegramIcon />}>
              <Link to="/channels">Каналы</Link>
            </Menu.Item>
          </SubMenu>
        </>
      )}
      {up.role === 1 && (
        <>
          <SubMenu
            key="2"
            icon={<BugFilled />}
            title="Админпанель"
            onTitleClick={() => {
              setOpenKey(["2"]);
              history.push("/admin/users");
            }}
          >
            <Menu.Item key="21" icon={<TeamOutlined />}>
              <Link to="/admin/users">Пользователи</Link>
            </Menu.Item>
            <Menu.Item key="22" icon={<SlidersOutlined />}>
              <Link to="/admin/organizations">Организации</Link>
            </Menu.Item>
          </SubMenu>
        </>
      )}
    </Menu>
  );
};
