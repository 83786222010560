import { AudioOutlined } from "@ant-design/icons";
import { Button, Form, Popconfirm, Space, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlacklistModel, InputcallModel } from "../../models/ServerModels";
import { appSelector } from "../../stores/AppSlice";
import { createBlacklist, deleteBlacklist } from "../../stores/BlacklistSlice";
import {
  fetchInputcalls,
  InputCallsFilter,
  inputcallsSelector,
} from "../../stores/InputcallsSlice";
import { GetColumnSearchProps } from "../Base/ColumnSearchProps";
import { AddToBLModal } from "./AddToBLModal";
import { InputCallInfo } from "./InputCallInfo";

export const InputCalls = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(appSelector);
  let { data, isLoading, total } = useSelector(inputcallsSelector);
  const [justRed, setJustRed] = useState(false);
  const [showBlocked, setShowBlocked] = useState(false);
  const [localData, setData] = useState(data);
  const [hoveredRecordId, setHoveredRecordId] = useState("");
  const [removingKey, setRemovingKey] = useState("");
  const [filter, setFilter] = useState<InputCallsFilter>({
    onPage: 10,
    page: 1,
    from: "",
    to: "",
    client: "",
    account: "",
    phone: "",
    noAnswer: false,
    showBlocked: false,
  });

  const onDelete = (call: InputcallModel, comment: string) => {
    if (call.isInBlacklist === false) {
      const item: BlacklistModel = {
        number: call.callerNumber,
        comment: comment,
        id: "",
        callsCount: 0,
        lastActivity: "",
        activityNum: 0,
      };
      dispatch(createBlacklist(item, token, filter));
    } else {
      dispatch(deleteBlacklist(call.id, token, filter));
    }
  };

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    dispatch(fetchInputcalls(token, filter));
  }, [filter]);

  const justRedClick = () => {
    setFilter({ ...filter, noAnswer: !filter.noAnswer });
    setJustRed(!justRed);
  };

  const showBlockedClick = () => {
    setFilter({ ...filter, showBlocked: !filter.showBlocked });
    setShowBlocked(!showBlocked);
  };

  const columns = [
    {
      title: "Дата звонка",
      dataIndex: "callStart",
      width: "20%",
      //...GetColumnSearchProps("callStart", "Дата звонка"),
    },
    {
      title: "Клиент",
      dataIndex: "callerNumber",
      width: "20%",
      ...GetColumnSearchProps("callerNumber", "Клиент"),
    },
    {
      title: "Звонков",
      dataIndex: "callsCount",
      width: "10%",
      //...GetColumnSearchProps("callsCount", "Звонков"),
    },
    {
      title: "Номер",
      dataIndex: "phoneName",
      width: "20%",
      ...GetColumnSearchProps("phoneName", "Номер"),
    },
    {
      title: "Аккаунт",
      dataIndex: "accountName",
      width: "20%",
      ...GetColumnSearchProps("accountName", "Аккаунт"),
    },
    {
      title: "Команды",
      width: "5%",
      className: "padding0",
      dataIndex: "_operation_",
      render: (_: any, record: InputcallModel) => {
        if (hoveredRecordId !== record.id && removingKey !== record.id) {
          return "";
        }

        if (record.isInBlacklist === false)
          return (
            <Space>
              <AddToBLModal
                record={record}
                setRemovingKey={setRemovingKey}
                onDelete={onDelete}
              />
            </Space>
          );

        return (
          <Space>
            <Popconfirm
              title="Уверены что хотите убрать номер из черного списка?"
              okText="Да"
              cancelText="Отмена"
              onConfirm={() => {
                setRemovingKey("");
                onDelete(record, "");
              }}
              onCancel={() => setRemovingKey("")}
            >
              <Button
                type={"ghost"}
                onClick={() => setRemovingKey(record.id)}
                style={{ paddingTop: "1px" }}
                shape="circle"
                icon={<AudioOutlined />}
              />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Form layout="inline" style={{ marginBottom: 16 }}>
        <Form.Item label="Без ответа">
          <Switch checked={justRed} onChange={justRedClick} />
        </Form.Item>
        <Form.Item label="Заблокированные">
          <Switch checked={showBlocked} onChange={showBlockedClick} />
        </Form.Item>
      </Form>
      <Table
        rowKey="id"
        loading={isLoading}
        title={() => <h3>Входящие звонки</h3>}
        dataSource={localData}
        expandable={{
          expandedRowRender: (record: InputcallModel) => {
            return <InputCallInfo record={record} />;
          },
          rowExpandable: () => true,
        }}
        rowClassName={(record) =>
          record.isInBlacklist
            ? "grey"
            : record.disposition !== "answered"
            ? "red"
            : ""
        }
        onChange={(pagination, filters, sorter) => {
          setFilter({
            ...filter,
            page: pagination.current!,
            onPage: pagination.pageSize!,
            account:
              filters.accountName !== null
                ? filters.accountName.toString()
                : "",
            phone:
              filters.phoneName !== null ? filters.phoneName.toString() : "",
            client:
              filters.callerNumber !== null
                ? filters.callerNumber.toString()
                : "",
          });
        }}
        columns={columns}
        pagination={{
          total: total,
          current: filter.page,
          pageSize: filter.onPage,
        }}
        onRow={(record) => {
          return {
            onMouseEnter: () => {
              if (record.id === removingKey) {
                setHoveredRecordId(record.id);
              } else if (removingKey === "") setHoveredRecordId(record.id);
            },
            onMouseLeave: () => {
              if (removingKey !== record.id) setHoveredRecordId("");
            },
          };
        }}
      />
    </>
  );
};
