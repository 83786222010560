import { Col, Layout, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputcallModel } from "../../models/ServerModels";
import { CallNotify } from "../Calls/CallNotify";
import { AvatarMenu } from "./AvatarMenu";
import { LeftMenu } from "./LeftMenu";

const { Header, Content, Sider } = Layout;

export const AppLayout = ({ children }: any) => {
  const [collapsed] = useState(false);

  const [calls] = useState<InputcallModel[]>([]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width="220"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo">
          <Link to="/">
            <img src="logo192.png" height="62px" alt="BCRM" />{" "}
            {!collapsed && <span className="logoText">БЕТОН CRM</span>}
          </Link>
        </div>
        <LeftMenu />
      </Sider>
      <Layout className="site-layout">
        <Header>
          <Row>
            <Col flex="1 1">
              {calls.map((c: InputcallModel) => (
                <CallNotify
                  type="new"
                  key={c.id}
                  callId={c.id}
                  phone={c.callerNumber}
                />
              ))}
            </Col>
            <Col flex="0 1">
              <AvatarMenu />
            </Col>
          </Row>
        </Header>
        <Content className="contentLayout">{children}</Content>
      </Layout>
    </Layout>
  );
};
