import { BlacklistModel } from "../models/ServerModels";
import { getSlice } from "./BaseSlice";
import { fetchInputcalls, InputCallsFilter } from "./InputcallsSlice";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

export const blackListSlice = getSlice<BlacklistModel>("blacklists");
const { setData, setLoading, modifyItem, removeItem } = blackListSlice.actions;

const baseApiUrl = "/api/blacklist/";

export const fetchBlacklist =
  (token: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const response = await fetch(baseApiUrl, {
          headers: { authorization: "Bearer " + token },
        });
        dispatch(setData(await response.json()));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    };

export const createBlacklist =
  (item: BlacklistModel, token: string, filter: InputCallsFilter): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl, {
          method: "POST",
          body: JSON.stringify({ number: item.number, comment: item.comment }),
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });

        dispatch(fetchInputcalls(token, filter));
      } catch (error) {
        console.log(error);
      }
    };

export const deleteBlacklist =
  (id: string, token: string, filter: InputCallsFilter): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl + id, {
          method: "DELETE",
          headers: { authorization: "Bearer " + token },
        });
        dispatch(removeItem(id));
        dispatch(fetchInputcalls(token, filter));
      } catch (err) {
        console.log(err);
      }
    };

export const deleteBlacklistOutOfMonitor =
  (id: string, token: string): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl + id, {
          method: "DELETE",
          headers: { authorization: "Bearer " + token },
        });
        dispatch(removeItem(id));
      } catch (err) {
        console.log(err);
      }
    };

export const editBlacklist =
  (id: string, item: BlacklistModel, token: string): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl + id, {
          method: "PUT",
          body: JSON.stringify({
            Id: id,
            ...item,
          }),
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        const newItemVM = await fetch(baseApiUrl + id, {
          headers: { authorization: "Bearer " + token },
        });
        dispatch(modifyItem(await newItemVM.json()));
      } catch (err) {
        console.log(err);
      }
    };

export const blacklistsSelector = (state: RootState) => state.blacklist;
export default blackListSlice.reducer;
