import { PhoneTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

export const CallNotify = ({
  type,
  phone,
  callId,
}: {
  type: "new" | "cancel" | "inprogress";
  phone: string;
  callId: string;
}) => {
  let className = "heartbeat";
  if (type !== "new") {
    className = "";
  }

  return (
    <Button className={className} size="large" icon={<PhoneTwoTone />}>
      {phone}
    </Button>
  );
};
