import { IIndexable } from "./ServerModels";

export function SortFunc(fieldName: string, a: IIndexable, b: IIndexable) {
  if (!a[fieldName]) return -1;
  if (!b[fieldName]) return 1;
  return a[fieldName].localeCompare(b[fieldName], "ru", { numeric: true });
}

export function NumberFunc(fieldName: string, a: IIndexable, b: IIndexable) {
  const x1: number = Number.parseInt(a[fieldName]);
  const x2: number = Number.parseInt(b[fieldName]);
  return x1 - x2;
}
