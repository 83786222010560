import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountModel } from "../../models/ServerModels";
import { NumberFunc, SortFunc } from "../../models/Sorter";
import {
  accountsSelector,
  createAccount,
  deleteAccount,
  editAccount,
  fetchAccounts,
} from "../../stores/AccountsSlice";
import { appSelector } from "../../stores/AppSlice";
import { BaseCreateModal } from "../Base/BaseCreateModal";
import { EditableTable } from "../Base/EditableTable";
import { EmptyTable } from "../Base/EmptyTable";
import { GroupFilter } from "../Channels/GroupFilter";
import { SelectGroupItem } from "../Channels/SelectGroupItem";

export const Accounts = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(appSelector);
  let { data, isLoading } = useSelector(accountsSelector);
  const [groups, setGroups] = useState<string[]>();
  const [filterGrp, setFilterGrp] = useState<string[]>();

  useEffect(() => {
    setGroups(
      data
        .map((x) => x.group)
        .filter((val, idx, self) => self.indexOf(val) === idx && val)
    );
  }, [data]);

  useEffect(() => {
    dispatch(fetchAccounts(token));
  }, []);

  if (filterGrp && filterGrp.length) {
    data = data.filter(
      (x) =>
        filterGrp?.indexOf(x.group) !== -1 ||
        (filterGrp.indexOf("///__none__///") !== -1 && !x.group)
    );
  }

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      editable: true,
      width: "18%",
      sorter: (x: AccountModel, y: AccountModel) => SortFunc("key", x, y),
      render: (text: string) =>
        text.substr(0, 3) + "......." + text.substr(-3, 3),
    },
    {
      title: "Secret",
      dataIndex: "secret",
      editable: true,
      width: "18%",
      sorter: (x: AccountModel, y: AccountModel) => SortFunc("secret", x, y),
      render: (text: string) =>
        text.substr(0, 3) + "......." + text.substr(-3, 3),
    },
    {
      title: "Описание",
      dataIndex: "name",
      editable: true,
      width: "18%",
      sorter: (x: AccountModel, y: AccountModel) => SortFunc("name", x, y),
    },
    {
      title: "Баланс",
      dataIndex: "balance",
      editable: true,
      width: "18%",
      sorter: (x: AccountModel, y: AccountModel) => NumberFunc("balance", x, y),
    },
    {
      title: "Группа",
      dataIndex: "group",
      editable: true,
      width: "18%",
      sorter: (x: AccountModel, y: AccountModel) => SortFunc("group", x, y),
    },
  ];

  const formItems = [
    <Form.Item key="key" label="Key" name="key" rules={[{ required: true }]}>
      <Input name="key" maxLength={60} />
    </Form.Item>,
    <Form.Item
      key="secret"
      label="Secret"
      name="secret"
      rules={[{ required: true }]}
    >
      <Input name="secret" maxLength={60} />
    </Form.Item>,
    <Form.Item
      key="name"
      label="Описание"
      name="name"
      rules={[{ required: true }]}
    >
      <Input name="name" maxLength={60} />
    </Form.Item>,
    <Form.Item key="group" label="Группа" name="group">
      <SelectGroupItem groupsProp={groups} />
    </Form.Item>,
  ];

  const onSave = (id: string, item: AccountModel) => {
    dispatch(editAccount(id, item, token));
  };

  const extraButtons = (
    <>
      <BaseCreateModal<AccountModel>
        key={1}
        title="Новый аккаунт"
        formItems={formItems}
        createAction={createAccount}
      >
        {formItems.map((f) => f)}
      </BaseCreateModal>
      <GroupFilter
        style={{ marginLeft: "20px" }}
        key={3}
        groups={groups}
        handleChange={(value: string[]) => setFilterGrp(value)}
      />
    </>
  );

  const resultNode =
    isLoading === false ? (
      <EditableTable<AccountModel>
        loading={isLoading}
        dataSource={data}
        columns={columns}
        onSave={(id: string, item: AccountModel) => onSave(id, item)}
        onDelete={(id: string) => {
          dispatch(deleteAccount(id, token));
        }}
        extraTopButtons={extraButtons}
        title="Аккаунты телефонии"
        formItems={formItems}
      />
    ) : (
      <EmptyTable columns={columns} />
    );

  return resultNode;
};
