import { createSlice } from "@reduxjs/toolkit";
import { AuthSettings, getMe, login } from "../api/auth";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    notifyCount: 0,
    userMeta: {},
    userProfile: null,
    token: localStorage.getItem('token') ?? "",
    error: null,
  },
  reducers: {
    fetchNotifications: (state) => {
      state.notifyCount = 0;
    },
    readAllNotifications: (state) => {
      state.notifyCount = 0;
    },
    setUserProfile: (state, { payload }) => {
      if (payload.error) {
        state.error = payload.error;
        state.userProfile = null;
      } else {
        state.userProfile = payload;
      }
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    }
  },
});

export const { fetchNotifications, readAllNotifications, setUserProfile, setToken } =
  appSlice.actions;

export const FetchMe =
  (token: string): AppThunk =>
    async (dispatch) => {
      try {
        const me: AuthSettings | null = await getMe(token);
        if (!me) {
          dispatch(setToken(""));
        } else
          dispatch(setUserProfile(me));
      } catch (err) {
        dispatch(
          setUserProfile({
            error: "Ошибка получения профиля, обратитесь к администратору",
          })
        );
      }
    };

export const Login = (username: string, password: string): AppThunk =>
  async (dispatch) => {
    var token = (await login(username, password)).token;
    localStorage.setItem('token', token);
    dispatch(setToken(token));
    const me: AuthSettings | null = await getMe(token);
    dispatch(setUserProfile(me));
  };


export const appSelector = (state: RootState) => state.app;
export default appSlice.reducer;
