import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TildaSettingsModel } from "../../models/ServerModels";
import { SortFunc } from "../../models/Sorter";
import { appSelector } from "../../stores/AppSlice";
import { channelsSelector, fetchChannels } from "../../stores/ChannelsSlice";
import { deleteGroup, getOneGroup } from "../../stores/GroupsSlice";
import { fetchSites, SitesDataSelector } from "../../stores/SitesSlice";
import {
  createTildaSettings,
  editTildaSettings,
  fetchTildaSettings,
  tildaSettingsSelector,
} from "../../stores/TildaSettingsSlice";
import { BaseCreateModal } from "../Base/BaseCreateModal";
import { EditableTable } from "../Base/EditableTable";
import { EmptyTable } from "../Base/EmptyTable";
import { SelectChannelItem } from "../Groups/SelectChannelItem";
import { SitesSelector } from "./SitesSelector";

export const TildaSettings = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(appSelector);
  let { data, isLoading } = useSelector(tildaSettingsSelector);
  const { data: sites, isLoading: isSitesLoading } =
    useSelector(SitesDataSelector);
  const chns = useSelector(channelsSelector).channels;
  const [channels, setChannels] = useState<{ id: string; name: string }[]>();
  const [filterGrp, setFilterGrp] = useState<string[]>();
  const [isModalShown, setIsModalShown] = useState(false);
  const [selectedSettingsId, setSelectedSettingsId] = useState("");

  useEffect(() => {
    setChannels(
      chns
        .map((x) => {
          return { id: x.id, name: x.name };
        })
        .filter((val, idx, self) => self.indexOf(val) === idx && val)
    );
  }, [chns]);

  useEffect(() => {
    dispatch(fetchTildaSettings(token));
    dispatch(fetchChannels(token));
    dispatch(fetchSites(token));
  }, []);

  if (filterGrp && filterGrp.length) {
    data = data.filter((x) => filterGrp?.indexOf(x.channelId) !== -1);
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      editable: true,
      width: "22%",
      sorter: (x: TildaSettingsModel, y: TildaSettingsModel) =>
        SortFunc("name", x, y),
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      editable: true,
      width: "22%",
      sorter: (x: TildaSettingsModel, y: TildaSettingsModel) =>
        SortFunc("comment", x, y),
    },
    {
      title: "Сайты",
      dataIndex: "sitesCount",
      editable: true,
      width: "22%",
      render: (text: any, row: TildaSettingsModel) => {
        if (row.id === "00000000-0000-0000-0000-000000000000") {
          return "";
        }
        if (!isSitesLoading) {
          const numbersWord =
            text === 1
              ? "сайт"
              : text === 2 || text === 3 || text === 4
              ? "сайта"
              : "сайтов";

          return (
            <Button
              type="link"
              onClick={() => {
                setIsModalShown(true);
                setSelectedSettingsId(row.id);
              }}
            >
              {text} {numbersWord}
            </Button>
          );
        } else {
          <Spin />;
        }
      },
    },
    {
      title: "Канал",
      dataIndex: "channelName",
      editableDataIndex: "channelId",
      editable: true,
      width: "22%",
      sorter: (x: TildaSettingsModel, y: TildaSettingsModel) =>
        SortFunc("channelName", x, y),
    },
  ];
  const formItems = [
    <Form.Item
      key="name"
      label="Название"
      name="name"
      rules={[{ required: true }]}
    >
      <Input maxLength={100} />
    </Form.Item>,
    <Form.Item key="comment" label="Комментарий" name="comment">
      <Input maxLength={500} type="text" />
    </Form.Item>,
    <Form.Item
      key="channelId"
      label="Канал"
      name="channelId"
      rules={[{ required: true }]}
    >
      <SelectChannelItem channels={channels} />
    </Form.Item>,
  ];

  const onSave = (id: string, item: TildaSettingsModel) => {
    dispatch(editTildaSettings(id, item, token));
  };

  const extraButtons = (
    <>
      <BaseCreateModal<TildaSettingsModel>
        title="Новая настройка"
        formItems={formItems}
        createAction={createTildaSettings}
      >
        {formItems.map((f) => f)}
      </BaseCreateModal>
    </>
  );

  const resultNode =
    isLoading === false ? (
      <>
        <EditableTable<TildaSettingsModel>
          loading={isLoading}
          dataSource={data}
          columns={columns}
          onSave={(id: string, item: TildaSettingsModel) => onSave(id, item)}
          onDelete={(id: string) => {
            dispatch(deleteGroup(id, token));
          }}
          extraTopButtons={extraButtons}
          title="Настройки Tildaforms"
          formItems={formItems}
        />
        <SitesSelector
          shown={isModalShown}
          sites={sites}
          settingId={selectedSettingsId}
          onModalClose={(p = false) => {
            if (p) {
              dispatch(getOneGroup(selectedSettingsId, token));
            }
            setIsModalShown(false);
          }}
        />
      </>
    ) : (
      <EmptyTable columns={columns} />
    );

  return resultNode;
};
