import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChannelModel } from "../../models/ServerModels";
import { SortFunc } from "../../models/Sorter";
import { appSelector } from "../../stores/AppSlice";
import {
  channelsSelector,
  createChannel,
  deleteChannel,
  editChannel,
  fetchChannels,
} from "../../stores/ChannelsSlice";
import { BaseCreateModal } from "../Base/BaseCreateModal";
import { EditableTable } from "../Base/EditableTable";
import { EmptyTable } from "../Base/EmptyTable";
import { GroupFilter } from "./GroupFilter";
import { SelectGroupItem } from "./SelectGroupItem";
import { WhereChatIdModal } from "./WhereChatIdModal";

export const Channels = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(appSelector);
  let { channels, isLoading } = useSelector(channelsSelector);
  const [groups, setGroups] = useState<string[]>();
  const [filterGrp, setFilterGrp] = useState<string[]>();

  useEffect(() => {
    setGroups(
      channels
        .map((x) => x.group)
        .filter((val, idx, self) => self.indexOf(val) === idx && val)
    );
  }, [channels]);

  useEffect(() => {
    dispatch(fetchChannels(token));
  }, []);

  if (filterGrp && filterGrp.length) {
    channels = channels.filter(
      (x) =>
        filterGrp?.indexOf(x.group) !== -1 ||
        (filterGrp.indexOf("///__none__///") !== -1 && !x.group)
    );
  }

  const columns = [
    {
      title: "ChatId",
      dataIndex: "chatId",
      editable: true,
      width: "30%",
      sorter: (x: ChannelModel, y: ChannelModel) => SortFunc("chatId", x, y),
      render: (text: string) =>
        text.substr(0, 3) + "......." + text.substr(-2, 2),
    },
    {
      title: "Описание",
      dataIndex: "name",
      editable: true,
      width: "30%",
      sorter: (x: ChannelModel, y: ChannelModel) => SortFunc("name", x, y),
    },
    {
      title: "Группа",
      dataIndex: "group",
      editable: true,
      width: "30%",
      sorter: (x: ChannelModel, y: ChannelModel) => SortFunc("group", x, y),
    },
  ];

  const formItems = [
    <Form.Item
      key="chatId"
      label="ChatId"
      name="chatId"
      rules={[{ required: true }]}
    >
      <Input name="chatId" maxLength={60} />
    </Form.Item>,
    <Form.Item key="name" label="Описание" name="name">
      <Input maxLength={100} />
    </Form.Item>,
    <Form.Item key="group" label="Группа" name="group">
      <SelectGroupItem groupsProp={groups} />
    </Form.Item>,
  ];

  const onSave = (id: string, item: ChannelModel) => {
    dispatch(editChannel(id, item, token));
  };

  const extraTopButtons = (
    <>
      <BaseCreateModal<ChannelModel>
        title="Новый канал"
        formItems={formItems}
        createAction={createChannel}
      >
        {formItems.map((f) => f)}
      </BaseCreateModal>
      <WhereChatIdModal />
      <GroupFilter
        groups={groups}
        handleChange={(value: string[]) => setFilterGrp(value)}
      />
    </>
  );

  const resultNode =
    isLoading === false ? (
      <EditableTable<ChannelModel>
        loading={isLoading}
        dataSource={channels}
        columns={columns}
        onSave={(id: string, item: ChannelModel) => onSave(id, item)}
        onDelete={(id: string) => {
          dispatch(deleteChannel(id, token));
        }}
        extraTopButtons={extraTopButtons}
        title="Telegram каналы"
        formItems={formItems}
      />
    ) : (
      <EmptyTable columns={columns} />
    );

  return resultNode;
};
