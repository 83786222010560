import {
  FallOutlined,
  PieChartOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Divider, Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PhoneStatisticModel } from "../../models/ServerModels";
import { NumberFunc, SortFunc } from "../../models/Sorter";
import { appSelector } from "../../stores/AppSlice";
import {
  fetchPhonesStatistic,
  PhonesStatisticSelector,
} from "../../stores/PhonesStatisticSlice";
import { GetColumnSearchProps } from "../Base/ColumnSearchProps";

export const PhonesStatistic = () => {
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector(PhonesStatisticSelector);
  const { token } = useSelector(appSelector);

  useEffect(() => {
    dispatch(fetchPhonesStatistic(token));
  }, []);

  const columns = [
    {
      title: "Номер",
      dataIndex: "phone",
      width: "20%",
      sorter: (x: PhoneStatisticModel, y: PhoneStatisticModel) =>
        SortFunc("phone", x, y),
      ...GetColumnSearchProps("phone", "Номер"),
    },
    {
      title: "За все время",
      dataIndex: "callsTotal",
      width: "20%",
      sorter: (x: PhoneStatisticModel, y: PhoneStatisticModel) =>
        NumberFunc("callsTotal", x, y),
    },
    {
      title: "За месяц",
      dataIndex: "callsLastMonth",
      width: "20%",
      sorter: (x: PhoneStatisticModel, y: PhoneStatisticModel) =>
        NumberFunc("callsLastMonth", x, y),
    },
    {
      title: "За 7 дней",
      dataIndex: "callsLastWeek",
      width: "20%",
      sorter: (x: PhoneStatisticModel, y: PhoneStatisticModel) =>
        NumberFunc("callsLastWeek", x, y),
    },
    {
      title: "Текущий тренд",
      dataIndex: "trendPercent",
      width: "20%",
      sorter: (x: PhoneStatisticModel, y: PhoneStatisticModel) =>
        NumberFunc("trendPercent", x, y),
      render: (value: string, item: PhoneStatisticModel) => {
        if (item.trendPercent === 0) {
          return "-";
        }
        if (item.trendPercent > 0) {
          return <RiseOutlined style={{ fontSize: "18px" }} />;
        } else {
          return <FallOutlined style={{ fontSize: "18px" }} />;
        }
      },
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <PieChartOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Статистика по номерам</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        loading={isLoading}
        pagination={{ defaultPageSize: 20 }}
      />
    </>
  );
};
