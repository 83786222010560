import { Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PhoneModel } from "../../models/ServerModels";
import { SortFunc } from "../../models/Sorter";
import { appSelector } from "../../stores/AppSlice";
import { GetColumnSearchProps } from "../Base/ColumnSearchProps";

export const PhonesSelector = ({
  groupId,
  phones,
  shown,
  onModalClose,
}: {
  groupId: string;
  phones: PhoneModel[];
  shown: boolean;
  onModalClose: Function;
}) => {
  const { token } = useSelector(appSelector);
  const [selectedPhones, setSelectedPhones] = useState<any>();

  const [phonesLocal, setPhonesLocal] = useState<PhoneModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOkDisabled, setIsOkDisabled] = useState(true);

  useEffect(() => {
    if (groupId)
      fetch("api/groups/getphones/" + groupId, {
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }).then((resp) =>
        resp.json().then((ids: string[]) => {
          if (ids && ids.length)
            setPhonesLocal([
              ...phones.filter((x) => ids.includes(x.id)),
              ...phones.filter((x) => !ids.includes(x.id)),
            ]);
          else setPhonesLocal([...phones]);
          setSelectedPhones(ids);
          setIsLoading(false);
        })
      );
  }, [groupId]);

  const onModalOk = () => {
    fetch("api/groups/setphones/" + groupId, {
      method: "PUT",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedPhones),
    }).then(() => {
      onModalClose(true);
    });
  };

  const onSelectionChange = (selectedRowKeys: any) => {
    setIsOkDisabled(false);
    console.log(selectedRowKeys);
    setSelectedPhones(selectedRowKeys);
  };

  const numberProps = GetColumnSearchProps("number", "Номер");
  const nameProps = GetColumnSearchProps("name", "Название");
  const accountNameProps = GetColumnSearchProps("accountName", "Аккаунт");

  return (
    <>
      <Modal
        width={1000}
        visible={shown}
        destroyOnClose={true}
        onCancel={() => onModalClose()}
        onOk={() => onModalOk()}
        title="Выбор телефонов"
        cancelText="Закрыть"
        okText="Применить изменения"
        okButtonProps={{ disabled: isOkDisabled }}
      >
        <Table
          dataSource={phonesLocal}
          loading={isLoading}
          size="small"
          rowKey="id"
          pagination={{
            pageSize: 15,
            showSizeChanger: false,
          }}
          rowSelection={{
            selectedRowKeys: selectedPhones,
            onChange: onSelectionChange,
          }}
          columns={[
            {
              title: "Номер",
              dataIndex: "number",
              width: "30%",
              sorter: (x: PhoneModel, y: PhoneModel) =>
                SortFunc("number", x, y),
              ...numberProps,
            },
            {
              title: "Название",
              dataIndex: "name",
              width: "30%",
              sorter: (x: PhoneModel, y: PhoneModel) => SortFunc("name", x, y),
              ...nameProps,
            },
            {
              title: "Аккаунт",
              dataIndex: "accountName",
              width: "30%",
              sorter: (x: PhoneModel, y: PhoneModel) =>
                SortFunc("accountName", x, y),
              ...accountNameProps,
            },
          ]}
        />
      </Modal>
    </>
  );
};
