import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Dropdown, Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appSelector, fetchNotifications } from "../../stores/AppSlice";
import { ProfileModal } from "./ProfileModal";

export const AvatarMenu = () => {
  const { notifyCount, token } = useSelector(appSelector);
  const dispatch = useDispatch();
  const [isProfileModalVisible, setProfileModalVisible] = useState(false);
  const avaSrc = "";

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const notifyMenuItemText =
    notifyCount > 0 ? <Badge dot> Оповещения</Badge> : " Оповещения";

  const avatarMenu = (
    <Menu>
      {/* <MenuItem
        icon={<NotificationOutlined />}
        onClick={() => dispatch(readAllNotifications())}
      >
        {notifyMenuItemText}
      </MenuItem> */}
      <MenuItem
        icon={<UserOutlined />}
        onClick={() => setProfileModalVisible(true)}
      >
        {" "}
        Профиль
      </MenuItem>
      <Menu.Divider />
      <MenuItem
        icon={<LogoutOutlined />}
        onClick={() => {
          console.log("LOGOUT");
        }}
      >
        {" "}
        Выход
      </MenuItem>
    </Menu>
  );
  return (
    <>
      <Dropdown
        overlay={avatarMenu}
        placement={"bottomCenter"}
        arrow
        trigger={["click"]}
      >
        <Badge count={notifyCount}>
          <Avatar
            src={avaSrc}
            style={{ cursor: "pointer" }}
            icon={<UserOutlined />}
          />
        </Badge>
      </Dropdown>
      <ProfileModal
        isProfileModalVisible={isProfileModalVisible}
        setProfileModalVisible={setProfileModalVisible}
      />
    </>
  );
};
