import { PhoneStatisticModel } from "../models/ServerModels";
import { getSlice } from "./BaseSlice";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

export const phonesStatisticSlice = getSlice<PhoneStatisticModel>("phonesStatistic");
const { setLoading, setData } = phonesStatisticSlice.actions;
const baseApiUrl = "/api/statistic/";

export const fetchPhonesStatistic =
  (token: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const response = await fetch(baseApiUrl, {
          headers: { authorization: "Bearer " + token },
        });
        dispatch(setData(await response.json()));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    };

export const PhonesStatisticSelector = (state: RootState) => state.phonesStatistic;
export default phonesStatisticSlice.reducer;
