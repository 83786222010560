import React from "react";
import { Select } from "antd";
const { Option } = Select;

export const SelectAccountItem = ({ accounts, ...props }: any) => {
  const { value } = props;
  const children = accounts.map((acc: { id: string; name: string }) => (
    <Option key={acc.id} value={acc.id}>
      {acc.name}
    </Option>
  ));

  return (
    <Select defaultValue={value} {...props}>
      {children}
    </Select>
  );
};
