import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlacklistModel } from "../../models/ServerModels";
import { NumberFunc, SortFunc } from "../../models/Sorter";
import { appSelector } from "../../stores/AppSlice";
import {
  blacklistsSelector,
  deleteBlacklistOutOfMonitor,
  editBlacklist,
  fetchBlacklist,
} from "../../stores/BlacklistSlice";
import { GetColumnSearchProps } from "../Base/ColumnSearchProps";
import { EditableTable } from "../Base/EditableTable";
import { InputCallsBL } from "./InputCallsBL";

export const Blacklist = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(appSelector);
  const { data, isLoading } = useSelector(blacklistsSelector);

  useEffect(() => {
    dispatch(fetchBlacklist(token));
  }, []);

  const columns = [
    {
      title: "Номер",
      dataIndex: "number",
      width: "22%",
      sorter: (x: BlacklistModel, y: BlacklistModel) =>
        SortFunc("number", x, y),
      ...GetColumnSearchProps("number", "Номер"),
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      editable: true,
      width: "22%",
      sorter: (x: BlacklistModel, y: BlacklistModel) =>
        SortFunc("comment", x, y),
      ...GetColumnSearchProps("comment", "Комментарий"),
    },
    {
      title: "Активность",
      dataIndex: "lastActivity",
      width: "22%",
      sorter: (x: BlacklistModel, y: BlacklistModel) =>
        NumberFunc("activityNum", x, y),
      ...GetColumnSearchProps("lastActivity", "Активность"),
    },
    {
      title: "Количество звонков",
      dataIndex: "callsCount",
      width: "22%",
      sorter: (x: BlacklistModel, y: BlacklistModel) =>
        NumberFunc("callsCount", x, y),
      ...GetColumnSearchProps("callsCount", "Количество звонков"),
    },
  ];

  const formItems = [
    <Form.Item
      key="comment"
      label="Комментарий"
      name="comment"
      rules={[{ required: true }]}
    >
      <Input name="comment" maxLength={60} />
    </Form.Item>,
  ];

  return (
    <EditableTable<BlacklistModel>
      loading={isLoading}
      dataSource={data}
      columns={columns}
      onSave={(id: string, item: BlacklistModel) =>
        dispatch(editBlacklist(id, item, token))
      }
      onDelete={(id: string) => {
        dispatch(deleteBlacklistOutOfMonitor(id, token));
      }}
      tableProps={{
        expandable: {
          expandedRowRender: (record: BlacklistModel) => {
            return <InputCallsBL number={record.number} />;
          },
          rowExpandable: () => true,
        },
      }}
      title="Номера в черном списке"
      formItems={formItems}
    />
  );
};
