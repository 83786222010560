import { Alert, Spin } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";

export const ErrorLayout = ({ message }: any) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ margin: "0 auto", marginTop: "20vh" }}>
        <img src="/truck.png" />
        <h3 style={{ marginTop: "10px" }}>{message}</h3>
      </Content>
    </Layout>
  );
};
