import { Button, Form, Input } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch } from "react-redux";
import { Login } from "../../stores/AppSlice";

export const LoginLayout = () => {
  const dispatch = useDispatch();
  const onFinish = (values: any) => {
    dispatch(Login(values["username"], values["password"]));
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ margin: "0 auto", marginTop: "40vh" }}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};
