import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: T;
  index: number;
  formItem: React.ReactNode;
  children: React.ReactNode;
}

const EditableCell = <T extends unknown>({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  formItem,
  ...restProps
}: EditableCellProps<T>) => {
  return <td {...restProps}>{editing ? formItem : children}</td>;
};

export const EditableTable = <T extends { id: string }>({
  dataSource,
  columns,
  loading,
  onSave,
  onDelete,
  extraTopButtons,
  title,
  formItems,
  tableProps,
}: {
  dataSource: T[];
  columns: any & { type: "text" | "number" };
  loading: boolean;
  onSave?: (id: string, item: T) => void;
  onDelete?: (id: string) => void;
  extraTopButtons?: React.ReactNode;
  title: string;
  formItems?: React.ReactNode[];
  tableProps?: any;
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState("");
  const [hoveredRecordId, setHoveredRecordId] = useState("");
  const [removingKey, setRemovingKey] = useState("");

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  const isEditing = (record: T) => record.id === editingKey;

  const edit = (record: Partial<T> & { id: string }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as T;

      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        if (onSave) onSave(id, row);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columnsWithButton = [
    ...columns,
    {
      title: "Команды",
      width: "10%",
      className: "padding0",
      dataIndex: "_operation_",
      render: (_: any, record: T) => {
        const editable = isEditing(record);
        const currentHovered = hoveredRecordId === record.id;
        const deleteButton =
          editable === false && onDelete ? (
            <Popconfirm
              title="Уверены что хотите удалить объект?"
              okText="Да"
              cancelText="Отмена"
              onConfirm={() => {
                if (onDelete) onDelete(record.id);
                setRemovingKey("");
              }}
              onCancel={() => setRemovingKey("")}
            >
              <Button
                type={"ghost"}
                onClick={() => setRemovingKey(record.id)}
                disabled={editingKey !== ""}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          ) : (
            ""
          );
        const editButton = editable ? (
          <Space>
            <Button
              type="ghost"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={() => save(record.id)}
            />
            <Popconfirm
              title="Уверены, что хотите отменить изменения?"
              onConfirm={cancel}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              okText="Да"
              cancelText="Вернуться"
            >
              <Button type="ghost" shape="circle" icon={<CloseOutlined />} />
            </Popconfirm>
          </Space>
        ) : (
          <Button
            type="ghost"
            disabled={editingKey !== ""}
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => edit(record)}
          />
        );

        if (
          currentHovered === false &&
          editingKey !== record.id &&
          removingKey !== record.id
        ) {
          return "";
        }

        const result = onSave ? (
          <Space>
            {editButton}
            {deleteButton}
          </Space>
        ) : (
          <Space>{deleteButton}</Space>
        );

        return result;
      },
    },
  ];

  const mergedColumns = columnsWithButton.map((col) => {
    if (!col.editable) {
      return col;
    }
    let formItem: any = null;
    if (formItems) {
      formItem = formItems.filter((x: any) => col.dataIndex === x.key);
      if (col.editableDataIndex)
        formItem = formItems.filter(
          (x: any) => col.editableDataIndex === x.key
        );
    }

    return {
      ...col,
      onCell: (record: T) => ({
        record,
        inputType: col.type,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        formItem: formItem,
      }),
    };
  });

  return (
    <>
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <PieChartOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Row className="buttonsRow">
        <Col>{extraTopButtons}</Col>
      </Row>

      <Form form={form} component={false}>
        <Table
          rowKey="id"
          //size="small"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          //scroll={{ y: height - 282 }}
          loading={loading}
          dataSource={data}
          sortDirections={["ascend", "descend", "ascend"]}
          columns={mergedColumns}
          rowClassName="editable-row"
          onRow={(record) => {
            return {
              onMouseEnter: () => {
                if (record.id === removingKey || record.id === editingKey) {
                  setHoveredRecordId(record.id);
                } else if (removingKey === "" && editingKey === "")
                  setHoveredRecordId(record.id);
              },
              onMouseLeave: () => {
                if (removingKey !== record.id && editingKey !== record.id)
                  setHoveredRecordId("");
              },
            };
          }}
          pagination={{
            onChange: cancel,
            defaultPageSize: 20,
          }}
          {...tableProps}
        />
      </Form>
    </>
  );
};
