/**
 * Базовый слайс для типовых гридов
 */

import { createSlice } from "@reduxjs/toolkit";

/**
 * Интерфейс стейта базового грида
 */
interface BaseDataState<TData extends { id: string }> {
  data: TData[];
  isLoading: boolean;
}

export const getSlice = <TData extends { id: string }>(name: string) => {
  const initState: BaseDataState<TData> = {
    data: [],
    isLoading: true,
  };
  return createSlice({
    name: name,
    initialState: initState,
    reducers: {
      setLoading: (state, { payload }) => {
        state.isLoading = payload;
      },
      setData: (state, { payload }) => {
        state.data = payload;
      },
      addItem: (state, { payload }) => {
        state.data = [...state.data, payload];
      },
      removeItem: (state, { payload }) => {
        state.data = state.data.filter((x) => x.id !== payload);
      },
      modifyItem: (state, { payload }) => {
        state.data = state.data.map((x) => {
          return x.id === payload.id ? payload : x;
        });
      },
    },
  });
};
