import { Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SiteModel } from "../../models/ServerModels";
import { SortFunc } from "../../models/Sorter";
import { appSelector } from "../../stores/AppSlice";
import { GetColumnSearchProps } from "../Base/ColumnSearchProps";

export const SitesSelector = ({
  settingId,
  sites,
  shown,
  onModalClose,
}: {
  settingId: string;
  sites: SiteModel[];
  shown: boolean;
  onModalClose: Function;
}) => {
  const { token } = useSelector(appSelector);
  const [selectedSites, setSelectedSites] = useState<any>();

  const [sitesLocal, setSitesLocal] = useState<SiteModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOkDisabled, setIsOkDisabled] = useState(true);

  useEffect(() => {
    if (settingId)
      fetch("/api/tildasettings/getsites/" + settingId, {
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }).then((resp) =>
        resp.json().then((ids: string[]) => {
          if (ids && ids.length)
            setSitesLocal([
              ...sites.filter((x) => ids.includes(x.id)),
              ...sites.filter((x) => !ids.includes(x.id)),
            ]);
          else setSitesLocal([...sites]);
          setSelectedSites(ids);
          setIsLoading(false);
        })
      );
  }, [settingId]);

  const onModalOk = () => {
    fetch("/api/tildasettings/setsites/" + settingId, {
      method: "PUT",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedSites),
    }).then(() => {
      onModalClose(true);
    });
  };

  const onSelectionChange = (selectedRowKeys: any) => {
    setIsOkDisabled(false);
    setSelectedSites(selectedRowKeys);
  };

  const domainProps = GetColumnSearchProps("domain", "Домен");

  return (
    <>
      <Modal
        width={1000}
        visible={shown}
        destroyOnClose={true}
        onCancel={() => onModalClose()}
        onOk={() => onModalOk()}
        title="Выбор сайтов"
        cancelText="Закрыть"
        okText="Применить изменения"
        okButtonProps={{ disabled: isOkDisabled }}
      >
        <Table
          dataSource={sitesLocal}
          loading={isLoading}
          size="small"
          rowKey="id"
          pagination={{
            pageSize: 15,
            showSizeChanger: false,
          }}
          rowSelection={{
            selectedRowKeys: selectedSites,
            onChange: onSelectionChange,
          }}
          columns={[
            {
              title: "Домен",
              dataIndex: "domain",
              width: "90%",
              sorter: (x: SiteModel, y: SiteModel) => SortFunc("domain", x, y),
              ...domainProps,
            },
          ]}
        />
      </Modal>
    </>
  );
};
