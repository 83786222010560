import { Select, Divider, Input } from "antd";
import React, { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
const { Option } = Select;

export const SelectGroupItem = ({ groupsProp, ...props }: any) => {
  const [group, setGroup] = useState<string>();
  const [groups, setGroups] = useState(groupsProp);
  const { value, onChange } = props;
  const [isLoading, setIsLoading] = useState(false);

  const addItem = () => {
    const grpLocal = group?.toString();
    if (grpLocal) {
      groups.push(grpLocal);
      setGroups([...groups]);
    }
    setGroup("");
    setIsLoading(false);
  };

  return (
    <Select
      {...props}
      defaultValue={value}
      allowClear
      onChange={(newVal, options) => {
        onChange(newVal, options);
      }}
      placeholder="Группа"
      loading={isLoading}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              padding: 8,
            }}
          >
            <Input
              required
              maxLength={60}
              //size="small"
              value={group}
              onPressEnter={() => {
                onChange(group, null);
                addItem();
              }}
              onChange={(event: any) => {
                setIsLoading(event.target.value !== "");
                setGroup(event.target.value);
              }}
            />
            <a
              style={{
                flex: "none",
                padding: "8px",
                display: "block",
                cursor: "pointer",
              }}
              onClick={addItem}
            >
              <PlusOutlined /> Добавить
            </a>
          </div>
        </div>
      )}
    >
      {groups.map((item: string) => (
        <Option value={item} key={item} disabled={isLoading}>
          {item}
        </Option>
      ))}
    </Select>
  );
};
