import { Button, Modal, Steps } from "antd";
import React from "react";
import { useState } from "react";
const { Step } = Steps;

export const WhereChatIdModal = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);

  return (
    <>
      <Button type="link" onClick={showModal}>
        Где взять ChatId?
      </Button>
      <Modal
        title="Где взять ChatId?"
        visible={isModalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={[<Button onClick={() => setModalVisible(false)}>Ок</Button>]}
      >
        <Steps direction="vertical">
          <Step
            key="1"
            status="finish"
            title="У вас должен быть бот с токеном вида XXXX:YYYYYYYYYYY"
            description="Токен получается при создании бота через BotFather"
          />
          <Step
            key="2"
            status="finish"
            title="Добавьте вашего бота в вашу группу"
            description="Не добавляйте в вашу группу сторонних ботов"
          />
          <Step
            key="3"
            status="finish"
            title="Откройте браузер и перейдите по адресу: https://api.telegram.org/botXXX:YYYYY/getUpdates"
            description="XXX:YYYYY замените на свой токен бота из шага 1"
          />
          <Step
            key="4"
            status="finish"
            title="В полученном ответе найдите запись в виде chatid=..."
            description="Это и есть ваш ChatId"
          />
        </Steps>
      </Modal>
    </>
  );
};
