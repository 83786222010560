import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrganizationModel } from "../../models/ServerModels";
import { appSelector } from "../../stores/AppSlice";
import {
  editOrganization,
  fetchOrganization,
  organizationSelector,
} from "../../stores/OrganizationSlice";
import { EditableTable } from "../Base/EditableTable";

export const OrganizationSettings = () => {
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector(organizationSelector);
  const { token } = useSelector(appSelector);

  useEffect(() => {
    dispatch(fetchOrganization(token));
  }, []);

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      editable: true,
      width: "20%",
    },
    {
      title: "Контактный телефон",
      dataIndex: "phone",
      editable: true,
      width: "20%",
    },
    {
      title: "Ключ бота Telegram",
      dataIndex: "telegramBotKey",
      editable: true,
      width: "20%",
      render: (text: string) =>
        text.substr(0, 5) + "......." + text.substr(-5, 5),
    },
    {
      title: "Дата регистрации",
      dataIndex: "registerDate",
      width: "20%",
    },
    {
      title: "Доступ до",
      dataIndex: "paidTo",
      width: "20%",
    },
  ];

  const formItems = [
    <Form.Item
      key="name"
      label="Название"
      name="name"
      rules={[{ required: true }]}
    >
      <Input name="name" maxLength={60} />
    </Form.Item>,
    <Form.Item
      key="phone"
      label="Контактный телефон"
      name="phone"
      rules={[{ required: true }]}
    >
      <Input name="phone" maxLength={60} />
    </Form.Item>,
    <Form.Item
      key="telegramBotKey"
      label="Ключ бота Telegram"
      name="telegramBotKey"
      rules={[{ required: true }]}
    >
      <Input name="telegramBotKey" maxLength={60} />
    </Form.Item>,
  ];

  return (
    <EditableTable<OrganizationModel>
      columns={columns}
      dataSource={data}
      loading={isLoading}
      onSave={(id: string, item: OrganizationModel) =>
        dispatch(editOrganization(id, item, token))
      }
      title="Настройки организации"
      formItems={formItems}
      tableProps={{ pagination: false }}
    />
  );
};
