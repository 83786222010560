export interface AuthSettings {
  userName: string;
  email: string;
  role: number;
  error: string;
}

export interface LoginAnswer {
  token: string;
}

export const getMe = async (token: string): Promise<AuthSettings | null> => {
  var resp = await fetch("/api/settings/getme", {
    headers: {
      authorization: "Bearer " + token,
    },
  });
  try {
    var json = await resp.json();
    return {
      userName: json.userName,
      email: json.email,
      role: json.role,
      error: json.error,
    };
  } catch (err) {
    return null;
  }
};

export const login = async (login: string, password: string): Promise<LoginAnswer> => {
  var resp = await fetch("/api/auth/login", {
    method: 'POST',
    body: JSON.stringify({ UserName: login, Password: password }),
    headers: { 'Content-Type': 'application/json' }
  });
  var json = await resp.json();
  return {
    token: json.token
  }
}