/**
 * Стор аккаунтов Задармы (телефонии)
 */

import { AccountModel } from "../models/ServerModels";
import { getSlice } from "./BaseSlice";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

export const AccountSlice = getSlice<AccountModel>("accounts");
const {
  setData,
  setLoading,
  modifyItem,
  removeItem,
  addItem,
} = AccountSlice.actions;

const baseApiUrl = "/api/accounts/";

export const fetchAccounts = (token: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetch(baseApiUrl, {
      headers: { authorization: "Bearer " + token },
    });
    dispatch(setData(await response.json()));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createAccount = (
  item: AccountModel,
  token: string
): AppThunk => async (dispatch) => {
  try {
    const response = await fetch(baseApiUrl, {
      method: "POST",
      body: JSON.stringify(item),
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    dispatch(addItem(json));
  } catch (error) {
    console.log(error);
  }
};

export const deleteAccount = (id: string, token: string): AppThunk => async (
  dispatch
) => {
  try {
    await fetch(baseApiUrl + id, {
      method: "DELETE",
      headers: { authorization: "Bearer " + token },
    });
    dispatch(removeItem(id));
  } catch (err) {
    console.log(err);
  }
};

export const editAccount = (
  id: string,
  item: AccountModel,
  token: string
): AppThunk => async (dispatch) => {
  try {
    await fetch(baseApiUrl + id, {
      method: "PUT",
      body: JSON.stringify({
        Id: id,
        ...item,
      }),
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    item.id = id;
    dispatch(modifyItem(item));
  } catch (err) {
    console.log(err);
  }
};

export const accountsSelector = (state: RootState) => state.accounts;
export default AccountSlice.reducer;
