import { ConfigProvider } from "antd";
import locale from "antd/es/locale/ru_RU";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.less";
import { RootLayout } from "./components/AppLayout/RootLayout";
import rootStore from "./stores/store";

function App() {
  return (
    <Router>
      <Provider store={rootStore}>
        <ConfigProvider locale={locale}>
          <RootLayout />
        </ConfigProvider>
      </Provider>
    </Router>
  );
}

export default App;
