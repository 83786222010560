import { PieChartOutlined } from "@ant-design/icons";
import { Breadcrumb, Divider, Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SiteModel } from "../../models/ServerModels";
import { NumberFunc, SortFunc } from "../../models/Sorter";
import { appSelector } from "../../stores/AppSlice";
import { fetchSites, SitesDataSelector } from "../../stores/SitesSlice";
import { GetColumnSearchProps } from "../Base/ColumnSearchProps";

export const Site = () => {
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector(SitesDataSelector);
  const { token } = useSelector(appSelector);

  useEffect(() => {
    dispatch(fetchSites(token));
  }, []);

  const columns = [
    {
      title: "Домен",
      dataIndex: "domain",
      width: "20%",
      sorter: (x: SiteModel, y: SiteModel) => SortFunc("domain", x, y),
      ...GetColumnSearchProps("domain", "Домен"),
      render: (value: string) => {
        return (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      },
    },
    {
      title: "Лидов",
      dataIndex: "leadsCount",
      width: "20%",
      sorter: (x: SiteModel, y: SiteModel) => NumberFunc("leadsCount", x, y),
    },
    {
      title: "Последний лид",
      dataIndex: "lastLeadDate",
      width: "20%",
      sorter: (x: SiteModel, y: SiteModel) =>
        NumberFunc("lastLeadDateNum", x, y),
    },
    {
      title: "Настройка оповещения",
      dataIndex: "notifyRule",
      width: "20%",
      sorter: (x: SiteModel, y: SiteModel) => SortFunc("notifyRule", x, y),
      ...GetColumnSearchProps("notifyRule", "Настройка оповещения"),
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <PieChartOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Сайты</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        loading={isLoading}
      />
    </>
  );
};
