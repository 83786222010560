import { OrganizationModel } from "../models/ServerModels";
import { getSlice } from "./BaseSlice";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

export const organizationSlice = getSlice<OrganizationModel>("organizations");
const { setLoading, setData, modifyItem } = organizationSlice.actions;
const baseApiUrl = "/api/organization/";

export const fetchOrganization =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await fetch(baseApiUrl, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(setData([await response.json()]));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const editOrganization =
  (id: string, item: OrganizationModel, token: string): AppThunk =>
  async (dispatch) => {
    try {
      await fetch(baseApiUrl + id, {
        method: "PUT",
        body: JSON.stringify({
          Id: id,
          ...item,
        }),
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      const newItemVM = await fetch(baseApiUrl + id, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(modifyItem(await newItemVM.json()));
    } catch (err) {
      console.log(err);
    }
  };

export const organizationSelector = (state: RootState) => state.organization;
export default organizationSlice.reducer;
