import { Button, Form, Input, Modal } from "antd";
import { AudioMutedOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import {
  BlacklistModel,
  InputcallModel,
  TildaLeadModel,
} from "../../models/ServerModels";

export const AddToBLModal = ({
  record,
  onDelete,
  setRemovingKey,
}: {
  record: InputcallModel | TildaLeadModel;
  onDelete: Function;
  setRemovingKey: Function;
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm<BlacklistModel>();

  const showModal = () => setModalVisible(true);

  const closeForm = () => {
    setModalVisible(false);
    setRemovingKey("");
  };

  const onBtnClick = () => {
    showModal();
    setRemovingKey(record.id);
  };

  const onOk = () => {
    const fields = form.getFieldsValue();
    onDelete(record, fields.comment);
    closeForm();
  };

  const number =
    record instanceof InputcallModel
      ? (record as InputcallModel).callerNumber
      : (record as TildaLeadModel).phone;

  const title = `Добавить номер ${number} в черный список`;

  return (
    <>
      <Button
        type={"ghost"}
        onClick={onBtnClick}
        style={{ paddingTop: "1px" }}
        shape="circle"
        icon={<AudioMutedOutlined />}
      />
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={closeForm}
        onCancel={closeForm}
        footer={[<Button onClick={onOk}>Ок</Button>]}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Form.Item key="comment" label="Комментарий" name="comment">
            <Input name="comment" maxLength={60} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
