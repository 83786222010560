import { createSlice } from "@reduxjs/toolkit";
import { InputcallModel } from "../models/ServerModels";
import { getSlice } from "./BaseSlice";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

interface InputCallsState {
  data: InputcallModel[];
  isLoading: boolean;
  total: number;
  //filter: InputCallsFilter;
}

export interface InputCallsFilter {
  from: string | null;
  to: string | null;
  client: string | null;
  phone: string | null;
  account: string | null;
  onPage: number;
  page: number;
  // field: string | null;
  // asc: boolean | null;
  noAnswer: boolean;
  showBlocked: boolean;
}

const initState: InputCallsState = {
  data: [],
  isLoading: true,
  total: 0,
};

export const inputcallsSlice = createSlice({
  name: "InputCall",
  initialState: initState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    // setFilter: (state, { payload }) => {
    //   state.filter = payload;
    // },
    setData: (state, { payload }) => {
      state.data = payload.items;
      state.total = payload.total;
    },
    addItem: (state, { payload }) => {
      state.data = [...state.data, payload];
      state.total = state.total + 1;
    },
    removeItem: (state, { payload }) => {
      state.data = state.data.filter((x) => x.id !== payload);
      state.total = state.total - 1;
    },
    modifyItem: (state, { payload }) => {
      state.data = state.data.map((x) => {
        return x.id === payload.id ? payload : x;
      });
    },
  },
});

const { setData, addItem, modifyItem, removeItem, setLoading } =
  inputcallsSlice.actions;

const baseApiUrl = "/api/monitoring";

export const fetchInputcalls =
  (token: string, filter: InputCallsFilter): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      //dispatch(setFilter(filter));
      let params = `?onPage=${filter.onPage}&page=${filter.page}&noAnswer=${filter.noAnswer}&showBlocked=${filter.showBlocked}`;
      //params += "&asc=${filter.asc}";
      params += "&account=" + filter.account;
      params += "&client=" + filter.client;
      //if (filter.field) params += "&field=" + filter.field;
      if (filter.from) params += "&from=" + filter.from;
      if (filter.to) params += "&to=" + filter.to;
      params += "&phone=" + filter.phone;
      const response = await fetch(baseApiUrl + `/getcalls` + params, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(setData(await response.json()));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createInputcall =
  (item: InputcallModel, token: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await fetch(baseApiUrl, {
        method: "POST",
        body: JSON.stringify(item),
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      const newItemVM = await fetch(baseApiUrl + json.id, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(addItem(await newItemVM.json()));
    } catch (error) {
      console.log(error);
    }
  };

export const deleteInputcall =
  (id: string, token: string): AppThunk =>
  async (dispatch) => {
    try {
      await fetch(baseApiUrl + id, {
        method: "DELETE",
        headers: { authorization: "Bearer " + token },
      });
      dispatch(removeItem(id));
    } catch (err) {
      console.log(err);
    }
  };

export const editInputcall =
  (id: string, item: InputcallModel, token: string): AppThunk =>
  async (dispatch) => {
    try {
      await fetch(baseApiUrl + id, {
        method: "PUT",
        body: JSON.stringify({
          Id: id,
          ...item,
        }),
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      const newItemVM = await fetch(baseApiUrl + id, {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(modifyItem(await newItemVM.json()));
    } catch (err) {
      console.log(err);
    }
  };

export const inputcallsSelector = (state: RootState) => state.inputcalls;
export default inputcallsSlice.reducer;
