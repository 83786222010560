import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PhoneModel } from "../../models/ServerModels";
import { SortFunc } from "../../models/Sorter";
import { accountsSelector, fetchAccounts } from "../../stores/AccountsSlice";
import { appSelector } from "../../stores/AppSlice";
import {
  createPhone,
  deletePhone,
  editPhone,
  fetchPhones,
  phonesSelector,
} from "../../stores/PhonesSlice";
import { BaseCreateModal } from "../Base/BaseCreateModal";
import { GetColumnSearchProps } from "../Base/ColumnSearchProps";
import { EditableTable } from "../Base/EditableTable";
import { EmptyTable } from "../Base/EmptyTable";
import { SelectAccountItem } from "./SelectAccountItem";

export const Phones = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(appSelector);
  let { data, isLoading } = useSelector(phonesSelector);
  const accs = useSelector(accountsSelector).data;
  const [accounts, setAccounts] = useState<{ id: string; name: string }[]>();
  const [filterGrp] = useState<string[]>();

  useEffect(() => {
    setAccounts(
      accs
        .map((x) => {
          return { id: x.id, name: x.name };
        })
        .filter((val, idx, self) => self.indexOf(val) === idx && val)
    );
  }, [accs]);

  useEffect(() => {
    dispatch(fetchPhones(token));
    dispatch(fetchAccounts(token));
  }, []);

  if (filterGrp && filterGrp.length) {
    data = data.filter((x) => filterGrp?.indexOf(x.accountId) !== -1);
  }

  const columns = [
    {
      title: "Номер",
      dataIndex: "number",
      editable: true,
      width: "30%",
      sorter: (x: PhoneModel, y: PhoneModel) => SortFunc("number", x, y),
      ...GetColumnSearchProps("number", "Номер"),
    },
    {
      title: "Название",
      dataIndex: "name",
      editable: true,
      width: "30%",
      sorter: (x: PhoneModel, y: PhoneModel) => SortFunc("name", x, y),
      ...GetColumnSearchProps("name", "Название"),
    },
    {
      title: "Аккаунт",
      dataIndex: "accountName",
      editableDataIndex: "accountId",
      editable: true,
      width: "30%",
      sorter: (x: PhoneModel, y: PhoneModel) => SortFunc("accountName", x, y),
      ...GetColumnSearchProps("accountName", "Аккаунт"),
    },
  ];

  const formItems = [
    <Form.Item
      key="number"
      label="Номер"
      name="number"
      rules={[{ required: true }]}
    >
      <Input name="number" maxLength={60} />
    </Form.Item>,
    <Form.Item
      key="name"
      label="Название"
      name="name"
      rules={[{ required: true }]}
    >
      <Input name="name" maxLength={60} />
    </Form.Item>,
    <Form.Item
      key="accountId"
      label="Аккаунт"
      name="accountId"
      rules={[{ required: true }]}
    >
      <SelectAccountItem accounts={accounts} />
    </Form.Item>,
  ];

  const onSave = (id: string, item: PhoneModel) => {
    dispatch(editPhone(id, item, token));
  };

  const extraButtons = (
    <>
      <BaseCreateModal<PhoneModel>
        title="Новый аккаунт"
        formItems={formItems}
        createAction={createPhone}
      >
        {formItems.map((f) => f)}
      </BaseCreateModal>
    </>
  );

  const resultNode =
    isLoading === false ? (
      <EditableTable<PhoneModel>
        loading={isLoading}
        dataSource={data}
        columns={columns}
        onSave={(id: string, item: PhoneModel) => onSave(id, item)}
        onDelete={(id: string) => {
          dispatch(deletePhone(id, token));
        }}
        extraTopButtons={extraButtons}
        title="Номера телефонии"
        formItems={formItems}
      />
    ) : (
      <EmptyTable columns={columns} />
    );

  return resultNode;
};
