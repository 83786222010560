export interface IIndexable {
  [key: string]: any;
}

/**
 * Модель канала Телеграм
 */
export class ChannelModel implements IIndexable {
  id: string;
  chatId: string;
  name: string;
  group: string;
}

/**
 * Модель аккаунта Задармы
 */
export class AccountModel implements IIndexable {
  id: string;
  key: string;
  secret: string;
  name: string;
  group: string;
  balance: string;
}

export class PhoneModel implements IIndexable {
  id: string;
  number: string;
  name: string;
  accountName: string;
  accountId: string;
}

export class GroupModel implements IIndexable {
  id: string;
  name: string;
  goodTemplate: string;
  badTemplate: string;
  channelId: string;
  channelName: string;
  phoneCount: number;
}

export class InputcallModel implements IIndexable {
  id: string;
  callStart: string;
  callId: string;
  callerNumber: string;
  calledNumber: string;
  internal: number;
  durationInSeconds: number;
  disposition: string;
  isRecorded: boolean;
  recId: string;
  notified: boolean;
  phoneId: string;
  phoneName: string;
  accountName: string;
  callsCount: number;
  isInBlacklist: boolean;
}

export class BlacklistModel implements IIndexable {
  id: string;
  number: string;
  comment: string;
  lastActivity: string;
  callsCount: number;
  activityNum: number;
}

export class OrganizationModel implements IIndexable {
  id: string;
  name: string;
  phone: string;
  registerDate: string;
  paidTo: string;
  telegramBotKey: string;
}

export class OrganizationUserModel implements IIndexable {
  id: string;
  userName: string;
  email: string;
  role: string;
}

export class TildaLeadModel implements IIndexable {
  id: string;
  name: string;
  phone: string;
  domain: string;
  time: string;
}

export class TildaSettingsModel implements IIndexable {
  id: string;
  name: string;
  comment: string;
  channelId: string;
  channelName: string;
  sitesCount: number;
}

export class SiteModel implements IIndexable {
  id: string;
  domain: string;
  leadsCount: number;
  lastLeadDate: string;
  lastLeadDateNum: number;
  notifyRule: string;
}

export class PhoneStatisticModel implements IIndexable {
  id: string;
  phone: string;
  callsTotal: number;
  callsLastWeek: number;
  callsLastMonth: number;
  trendPercent: number;
}