import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { appSelector, FetchMe } from "../../stores/AppSlice";
import { Accounts } from "../Accounts/Accounts";
import { Organizations } from "../Admin/Organizations";
import { UsersAdmin } from "../Admin/UsersAdmin";
import { Blacklist } from "../Blacklist/Blacklist";
import { Channels } from "../Channels/Channels";
import { Groups } from "../Groups/Groups";
import { Monitoring } from "../Monitoring/Monitoring";
import { OrganizationSettings } from "../Organization/OrganizationSettings";
import { Phones } from "../Phones/Phones";
import { Site } from "../Site/Site";
import { PhonesStatistic } from "../Statistic/PhonesStatistic";
import { TildaBlackList } from "../TildaBlackList/TildaBlackList";
import { TildaLeads } from "../TildaLeads/TildaLeads";
import { TildaSettings } from "../TildaSettings/TildaSettings";
import { AppLayout } from "./AppLayout";
import { ErrorLayout } from "./ErrorLayout";
import { LoadingLayout } from "./LoadingLayout";
import { LoginLayout } from "./LoginLayout";

export const RootLayout = () => {
  const [localToken, setLocalToken] = useState("");
  const { error, userProfile, token } = useSelector(appSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(FetchMe(token));
    }
  }, []);

  useEffect(() => {
    setLocalToken(token);
  }, [token]);

  if (!localToken) {
    return <LoginLayout />;
  }

  if (error) {
    return <ErrorLayout message={error} />;
  }

  if (!userProfile) {
    return <LoadingLayout />;
  }

  let routes: JSX.Element;
  const up = userProfile as any;

  const routesList = [];

  // common routes
  routesList.push(
    <Route key="/" exact path="/" component={Monitoring} />,
    <Route key="/phones" path="/phones" component={Phones} />
  );

  // director routes
  if (up.role < 3) {
    routesList.push(
      <Route key="/channels" path="/channels" component={Channels} />,
      <Route key="/accounts" path="/accounts" component={Accounts} />,
      <Route key="/groups" path="/groups" component={Groups} />,
      <Route key="/blacklist" path="/blacklist" component={Blacklist} />,
      <Route
        key="/organization/settings"
        path="/organization/settings"
        component={OrganizationSettings}
      />,
      // <Route
      //   key="/organization/users"
      //   path="/organization/users"
      //   component={Blacklist}
      // />,
      <Route
        key="/tildaforms/settings"
        path="/tildaforms/settings"
        component={TildaSettings}
      />,
      <Route
        key="/tildaforms/leads"
        path="/tildaforms/leads"
        component={TildaLeads}
      />,
      <Route
        key="/tildaforms/sites"
        path="/tildaforms/sites"
        component={Site}
      />,
      <Route
        key="/tildaforms/blacklist"
        path="/tildaforms/blacklist"
        component={TildaBlackList}
      />,
      <Route
        key="/statistic/phones"
        path="/statistic/phones"
        component={PhonesStatistic}
      />
    );
  }

  // admin routes
  if (up.role < 2) {
    routesList.push(
      <Route
        key="/admin/organizations"
        path="/admin/organizations"
        component={Organizations}
      />,
      <Route key="/admin/users" path="/admin/users" component={UsersAdmin} />
    );
  }

  routes = <>{routesList.map((x) => x)}</>;
  return (
    <AppLayout>
      <Suspense fallback={<div>Загрузка... Пожалуйста, подождите.</div>}>
        <Switch>{routes}</Switch>
      </Suspense>
    </AppLayout>
  );
};
