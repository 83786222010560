import { Select } from "antd";
import React from "react";
const { Option } = Select;

export const GroupFilter = ({ groups, handleChange, ...props }: any) => {
  const children = groups
    ? groups.map((grp: string) => (
        <Option key={grp} value={grp}>
          {grp}
        </Option>
      ))
    : [];

  children.unshift(
    <Option key="0" value="///__none__///">
      -- Без группы --
    </Option>
  );

  return (
    <Select
      mode="multiple"
      allowClear
      placeholder="Поиск по группе"
      onChange={handleChange}
      {...props}
      style={{ minWidth: 200, ...props.style }}
    >
      {children}
    </Select>
  );
};
