import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "./AppSlice";
import channelsReducer from "./ChannelsSlice";
import accountsReducer from "./AccountsSlice";
import phonesReducer from "./PhonesSlice";
import groupsReducer from "./GroupsSlice";
import inputcallsReducer from "./InputcallsSlice";
import blacklistReducer from "./BlacklistSlice";
import organizationReducer from "./OrganizationSlice";
import tildaLeadsReducer from './TildaLeadsSlice'
import tildaSettingsReducer from './TildaSettingsSlice'
import sitesReducer from './SitesSlice'
import phonesStatisticReducer from './PhonesStatisticSlice'
import tildablacklistReducer from './TildaBlackListSlice';

const rootReducer = combineReducers({
  app: appReducer,
  channels: channelsReducer,
  accounts: accountsReducer,
  phones: phonesReducer,
  groups: groupsReducer,
  inputcalls: inputcallsReducer,
  blacklist: blacklistReducer,
  organization: organizationReducer,
  tildaLeads: tildaLeadsReducer,
  tildaSettings: tildaSettingsReducer,
  sites: sitesReducer,
  phonesStatistic: phonesStatisticReducer,
  tildablacklist: tildablacklistReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
