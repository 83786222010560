import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlacklistModel, TildaLeadModel } from "../../models/ServerModels";
import { appSelector } from "../../stores/AppSlice";
import { createTildaBlacklist } from "../../stores/TildaBlackListSlice";
import {
  deleteTildaLead,
  fetchTildaLeads,
  tildaLeadsSelector,
} from "../../stores/TildaLeadsSlice";
import { AddToBLModal } from "../Monitoring/AddToBLModal";

export const TildaLeads = () => {
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector(tildaLeadsSelector);
  const { token } = useSelector(appSelector);
  const [hoveredRecordId, setHoveredRecordId] = useState("");
  const [removingKey, setRemovingKey] = useState("");

  useEffect(() => {
    dispatch(fetchTildaLeads(token));
  }, []);

  const onBlackList = (call: TildaLeadModel, comment: string) => {
    const item: BlacklistModel = {
      number: call.phone,
      comment: comment,
      id: "",
      callsCount: 0,
      lastActivity: "",
      activityNum: 0,
    };
    dispatch(createTildaBlacklist(item, token, call));
  };

  const onDelete = (id: string) => {
    dispatch(deleteTildaLead(id, token));
  };

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Контактный телефон",
      dataIndex: "phone",
      width: "20%",
    },
    {
      title: "Источник",
      dataIndex: "domain",
      width: "20%",
      render: (value: string, item: TildaLeadModel) => {
        return (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      },
    },
    {
      title: "Дата поступления",
      dataIndex: "time",
      width: "10%",
    },
    {
      title: "Команды",
      width: "20%",
      className: "padding0",
      dataIndex: "_operation_",
      render: (_: any, record: TildaLeadModel) => {
        if (hoveredRecordId !== record.id && removingKey !== record.id) {
          return "";
        }

        return (
          <Space>
            <Popconfirm
              title="Уверены что хотите удалить объект?"
              okText="Да"
              cancelText="Отмена"
              onConfirm={() => {
                if (onDelete) onDelete(record.id);
                setRemovingKey("");
              }}
              onCancel={() => setRemovingKey("")}
            >
              <Button
                type={"ghost"}
                onClick={() => setRemovingKey(record.id)}
                //disabled={hoveredRecordId !== ""}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
            <AddToBLModal
              record={record}
              setRemovingKey={setRemovingKey}
              onDelete={onBlackList}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      loading={isLoading}
      title={() => <h3>Входящие звонки</h3>}
      dataSource={data}
      expandable={{
        rowExpandable: () => true,
      }}
      columns={columns}
      pagination={{
        total: data.length,
      }}
      onRow={(record) => {
        return {
          onMouseEnter: () => {
            if (record.id === removingKey) {
              setHoveredRecordId(record.id);
            } else if (removingKey === "") setHoveredRecordId(record.id);
          },
          onMouseLeave: () => {
            if (removingKey !== record.id) setHoveredRecordId("");
          },
        };
      }}
    />
  );
};
