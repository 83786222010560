import { createSlice } from "@reduxjs/toolkit";
import { ChannelModel } from "../models/ServerModels";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";

interface ChannelsState {
  channels: ChannelModel[];
  isLoading: boolean;
}

const initialState: ChannelsState = {
  channels: [],
  isLoading: true,
};

export const channelSlice = createSlice({
  name: "channels",
  initialState: initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setChannels: (state, { payload }) => {
      state.channels = payload;
    },
    addChannel: (state, { payload }) => {
      state.channels = [...state.channels, payload];
    },
    removeChannel: (state, { payload }) => {
      state.channels = state.channels.filter((x) => x.id !== payload);
    },
    modifyChannel: (state, { payload }) => {
      state.channels = state.channels.map((x) => {
        return x.id === payload.id ? payload : x;
      });
    },
  },
});

const { setLoading, setChannels, addChannel, removeChannel, modifyChannel } =
  channelSlice.actions;

export const fetchChannels =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await fetch("/api/channels/", {
        headers: { authorization: "Bearer " + token },
      });
      dispatch(setChannels(await response.json()));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

/**
 * Диспатчит добавление канала
 * @param chatId ChatId
 * @param name Описание
 * @param group Группа
 * @param token Токен доступа к АПИ
 */
export const createChannel =
  (item: ChannelModel, token: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await fetch("/api/channels", {
        method: "POST",
        body: JSON.stringify(item),
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      dispatch(addChannel(json));
    } catch (error) {
      console.log(error);
    }
  };

/**
 * Диспатчит удаление канала из стора
 * @param id Id канала
 * @param token Токен доступа к АПИ
 */
export const deleteChannel =
  (id: string, token: string): AppThunk =>
  async (dispatch) => {
    try {
      await fetch("/api/channels/" + id, {
        method: "DELETE",
        headers: { authorization: "Bearer " + token },
      });
      dispatch(removeChannel(id));
    } catch (err) {
      console.log(err);
    }
  };

export const editChannel =
  (id: string, item: ChannelModel, token: string): AppThunk =>
  async (dispatch) => {
    try {
      await fetch("/api/channels/" + id, {
        method: "PUT",
        body: JSON.stringify({
          Id: id,
          ...item,
        }),
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      dispatch(
        modifyChannel({
          id: id,
          chatId: item.chatId,
          name: item.name,
          group: item.group,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

export const channelsSelector = (state: RootState) => state.channels;
export default channelSlice.reducer;
