import { Alert, Spin } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";

export const LoadingLayout = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ margin: "0 auto", marginTop: "40vh" }}>
        <Spin size="large"></Spin>
      </Content>
    </Layout>
  );
};
