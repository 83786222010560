import { BlacklistModel, TildaLeadModel } from "../models/ServerModels";
import { getSlice } from "./BaseSlice";
import { RootState } from "./rootReducer";
import { AppThunk } from "./store";
import { fetchTildaLeads } from "./TildaLeadsSlice";

export const tildaBlackListSlice = getSlice<BlacklistModel>("tildablacklists");
const { setData, setLoading, modifyItem, removeItem } = tildaBlackListSlice.actions;

const baseApiUrl = "/api/tildablacklist/";
export const fetchTildaBlacklist =
  (token: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const response = await fetch(baseApiUrl, {
          headers: { authorization: "Bearer " + token },
        });
        dispatch(setData(await response.json()));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    };

export const createTildaBlacklist =
  (item: BlacklistModel, token: string, filter: TildaLeadModel): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl, {
          method: "POST",
          body: JSON.stringify({ number: item.number, comment: item.comment }),
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        dispatch(fetchTildaLeads(token));
      } catch (error) {
        console.log(error);
      }
    };

export const deleteTildaBlacklist =
  (id: string, token: string): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl + id, {
          method: "DELETE",
          headers: { authorization: "Bearer " + token },
        });
        dispatch(removeItem(id));
      } catch (err) {
        console.log(err);
      }
    };

export const editTildaBlacklist =
  (id: string, item: BlacklistModel, token: string): AppThunk =>
    async (dispatch) => {
      try {
        await fetch(baseApiUrl + id, {
          method: "PUT",
          body: JSON.stringify({
            Id: id,
            ...item,
          }),
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        const newItemVM = await fetch(baseApiUrl + id, {
          headers: { authorization: "Bearer " + token },
        });
        dispatch(modifyItem(await newItemVM.json()));
      } catch (err) {
        console.log(err);
      }
    };

export const tildaBlacklistsSelector = (state: RootState) => state.tildablacklist;
export default tildaBlackListSlice.reducer;