import { Avatar, Button, Card, Modal } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthSettings, getMe } from "../../api/auth";
import { appSelector } from "../../stores/AppSlice";

export const ProfileModal = ({
  isProfileModalVisible,
  setProfileModalVisible,
}: any) => {
  const { userProfile } = useSelector(appSelector);

  const up: any = userProfile as any;
  const role =
    up.role === 1
      ? "Администратор"
      : up.role === 2
      ? "Директор"
      : "Менеджер по продажам";

  return (
    <Modal
      destroyOnClose
      title="Профиль пользователя"
      visible={isProfileModalVisible}
      onOk={() => setProfileModalVisible(false)}
      onCancel={() => setProfileModalVisible(false)}
      footer={[
        <Button key={"Закрыть"} onClick={() => setProfileModalVisible(false)}>
          Закрыть
        </Button>,
      ]}
    >
      {/* <div>
        <img src={user.picture} alt={user.name} />
        {dbUser && (
          <span>
            <h2>{dbUser.userName}</h2>
            <p>{dbUser.email}</p>
          </span>
        )}
      </div> */}
      <Card>
        <Meta title={up.userName} description={role} />
      </Card>
    </Modal>
  );
};
