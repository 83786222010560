import { PieChartOutlined } from "@ant-design/icons";
import { Breadcrumb, Divider } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import React from "react";
import { Link } from "react-router-dom";
import { InputCalls } from "./InputCalls";

export const Monitoring = () => {
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/">
            <PieChartOutlined /> Монитор
          </Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider />
      <InputCalls />
    </>
  );
};
