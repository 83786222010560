import { Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InputcallModel } from "../../models/ServerModels";
import { appSelector } from "../../stores/AppSlice";

export const InputCallInfo = ({ record }: { record: InputcallModel }) => {
  const { token } = useSelector(appSelector);
  const [data, setData] = useState<InputcallModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const filter = {
      onPage: 1000,
      page: 1,
      from: "",
      to: "",
      client: record.callerNumber,
      account: "",
      phone: "",
      noAnswer: false,
      showBlocked: true,
    };
    let params = `?onPage=${filter.onPage}&page=${filter.page}&noAnswer=${filter.noAnswer}&showBlocked=${filter.showBlocked}`;
    params += "&account=" + filter.account;
    params += "&client=" + filter.client;
    if (filter.from) params += "&from=" + filter.from;
    if (filter.to) params += "&to=" + filter.to;
    params += "&phone=" + filter.phone;
    fetch("/api/monitoring/getcalls" + params, {
      headers: { authorization: "Bearer " + token },
    }).then((resp) =>
      resp.json().then((data) => {
        setData(data.items);
        setIsLoading(false);
      })
    );
  }, []);

  const columns = [
    {
      title: "Запись",
      width: "20%",
      dataIndex: "_record_",
      render: (_: any, record: InputcallModel) => {
        return record.isRecorded ? (
          <audio controls style={{ height: "25px", width: "100%" }}>
            <source
              src={"/api/monitoring/record/" + record.id}
              type="audio/wav"
            />
            Ваш браузер не поддерживает элемент <code>audio</code>, но вы все
            еще можете скачать запись по{" "}
            <a href={"/api/monitoring/record/" + record.id}>ссылке</a>
          </audio>
        ) : (
          "Запись недоступна"
        );
      },
    },
    {
      title: "Дата",
      dataIndex: "callStart",
      width: "20%",
    },
    {
      title: "Статус",
      dataIndex: "disposition",
      width: "20%",
    },
    {
      title: "Номер",
      dataIndex: "phoneName",
      width: "20%",
    },
    {
      title: "Аккаунт",
      dataIndex: "accountName",
      width: "20%",
    },
  ];

  const audioNode = record.isRecorded ? (
    <audio controls>
      <source src={"/api/monitoring/record/" + record.id} type="audio/wav" />
      Ваш браузер не поддерживает элемент <code>audio</code>, но вы все еще
      можете скачать запись по{" "}
      <a href={"/api/monitoring/record/" + record.id}>ссылке</a>
    </audio>
  ) : (
    "Запись недоступна"
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {record.disposition === "answered" && audioNode}
      {/* <AudioRecorder /> */}
      <Table
        bordered={true}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        size="small"
        pagination={false}
        rowClassName={(rec) =>
          rec.id === record.id && data.length > 1 ? "boldRow" : ""
        }
      />
    </Space>
  );
};
